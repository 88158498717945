export const environment = {
  production: false,
  currency: '',

  // PARAMETROS ADMIN - Tactical Store
  firebase: {
    apiKey: "AIzaSyCXHMBA-w4dHsTgCWA9XVmn6br_MTu-b0Y",
    authDomain: "ianosapp-sv-fntacticalstorepro.firebaseapp.com",
    projectId: "ianosapp-sv-fntacticalstorepro",
    storageBucket: "ianosapp-sv-fntacticalstorepro.appspot.com",
    messagingSenderId: "1070062927608",
    appId: "1:1070062927608:web:5a1845e7831e64078c850d",
    measurementId: "G-QL59SPELV8"
  },
  googleMapsKey: 'AIzaSyC0N_UyDvlLly4CbXV3zOrGwgvmZIIWl_4',
  urlBase: 'https://us-central1-ianosapp-sv-fntacticalstorepro.cloudfunctions.net',
  apikey: '4602c13ebef-77f16c955f96e-4150-5192df39',

};
